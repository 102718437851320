import React, { useContext, useState } from "react";
import Header from "../../../header/Header";
import {
  HeroHeaderSyllabus,
  HeaderButton,
  HeroHeader,
} from "../../HeroHeaderSyllabus";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { DataContext } from "../../../../context/DataContext";
import { useLocation, useParams } from "react-router-dom";
import { ListContent } from "../../../reusableComponent/ListContent";
import Table2 from "../../../reusableComponent/Table2";
import { PrelimsContentBox } from "../../../reusableComponent/PrelimsContentBox";
import { RightSideBar } from "../../../rightSideBar/RightSideBar";

export const DetailSyllabus = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName, title } = useParams();
  const location = useLocation();
  const exam = exams && exams.find((element) => element.examName === examName);
  const subExam = exams && exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const syllabus = subExam && subExam.syllabus;
  // console.log("syllabus", subExam.syllabus);

  const questions =subExam && subExam.questions;
  const subjectQuestions = questions && questions.items.filter(
    (x) => x._type === "nSubject"
  );

  // let Questions = null;
  // Questions = questions.items.filter(
  //   (x) => x.title === title && x.topic == topic
  // );
  if (loading) return <></>;

  // const syllabus = exams[0].subExams[0].syllabus;
  // console.log("title", title);
  // console.log("Syllabus location Detail", location);
  let full = false;
  if (!title) full = true;
  else full = false;
  var headerButtons = [
    new HeaderButton(
      `${examName} ${subExamName} Questions`,
      `/${examName}/${subExamName}/questions`
    ),
    new HeaderButton(
      `${examName} ${subExamName} PYQs`,
      `/${examName}/${subExamName}/pyqs`
    ),
  ];
  var header = new HeroHeader(headerButtons, title, syllabus.author);
  return (
    <>
      <Header />
      <div className="mt-20">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <main className="flex items-start gap-8 lg:my-10 px-2 md:px-[2vw] lg:px-[4vw]">
        <div className="w-full lg:w-[75%] bg-[#FFF] flex flex-col items-start rounded-sm mb-4 p-2 lg:p-4 overflow-y-auto">
          {!full &&
            location &&
            location.state.syllabus.find((x) => x.title == title) &&
            location.state.syllabus
              .find((x) => x.title == title)
              .items.map((i) => {
                if (i._type == "listContent") {
                  return (
                    <div>
                      <ListContent listContents={[i]} />
                    </div>
                  );
                } else if (i._type == "tableData") {
                  return (
                    <div className="w-full">
                      <Table2 title={i.title} headers={i.headers} data={i} />
                    </div>
                  );
                } else if (i._type == "ContentBoxWithImageCheckbox") {
                  return (
                    <div className="w-full">
                      <PrelimsContentBox contentData={[i]} />
                    </div>
                  );
                } else if (i._type == "tableData") {
                  <div className="w-full">
                    <Table2 title={i.title} headers={i.headers} data={i} />
                  </div>
                }
              })}
        </div>
        <div className="hidden lg:block lg:w-[25%] sticky top-28 h-fit">
          <RightSideBar
            examName={examName}
            subExamName={subExamName}
            title={title}
          />
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
