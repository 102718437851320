import React from 'react'
import Header from '../header/Header'
import { HomeAnnouncementSec } from '../home/HomeAnnouncementSec'
import { Footer } from './Footer'
import ScrollToTopButton from '../../unusableComponent/utils/scrollToTopButton'

export const QuestionFooter = () => {
  return (
    <div>
        <Header />
        <div className='h-24'></div>
        <HomeAnnouncementSec />
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}
