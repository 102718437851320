import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { HeroHeaderSyllabus } from "../../HeroHeaderSyllabus";
import { HeaderButton } from "../../HeroHeaderSyllabus";
import { HeroHeader } from "../../HeroHeaderSyllabus";
import { SectionHeader } from "./Section";

export const QuestionPageDetail = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName, title } = useParams();
  let header = {};
  if (loading) return <></>;

  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  // console.log("Subexam",subExam);
  // console.log("SubexamName",subExamName);
  const syllabus = subExam.syllabus;
  // console.log("syllabus", subExam.syllabus)

  const questions = subExam.questions;
  let Questions = null;
  Questions = questions.items.filter((x) => x.title === title);
  // console.log("qs", questions)
  if (examName && subExamName && exams && syllabus) {
    var headerButtons = [
      new HeaderButton(`Exam notes`, `/notes`),
      new HeaderButton(
        `${examName} ${subExamName} PYQs`,
        `/${examName}/${subExamName}/pyqs`
      ),
    ];
    header = new HeroHeader(
      headerButtons,
      examName + " " + subExamName + " Questions",
      syllabus.author,
      syllabus.date
    );
  }

  // console.log("questions", Questions);
  return (
    <>
      <Header />
      <div className="mt-4">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <main className="flex items-start gap-5">
        <div className="question-detail-page lg:w-4/5 w-full p-2 md:py-[6vw] md:px-[4vw] flex flex-col items-start space-y-8 ">
          {title && (
            <h1 className="lg:text-[2.25vw] lg:leading-[5vw] text-lg text-[#007fff] font-extrabold capitalize">
              {title} Questions in {examName} {subExamName}
            </h1>
          )}
          {!title && (
            <h1 className="text-[2.25vw] text-[#007fff] font-extrabold capitalize">
              Full Questions in {examName} {subExamName}
            </h1>
          )}
          {Questions &&
            Questions.map((x) => {
              return x.items.map((y) => {
                if (y._type == "sectionHeading") {
                  return <SectionHeader value={y.value} />;
                }
                if (y._type == "mcq") {
                  return (
                    <div
                      key={y.id}
                      className="w-full flex flex-col items-start  rounded-sm  lg:px-4"
                    >
                      <div className="text-base font-normal text-[#2d2d2d]">
                        <BlockContent blocks={y.question} />
                      </div>
                      <div className="lg:my-2">
                        {y.options.map((z) => {
                          return (
                            <BlockContent key={z.id} blocks={z.optionValue} />
                          );
                        })}
                      </div>
                      <div className="my-2 flex flex-col items-start space-y-3">
                        <strong>Correct Answer: </strong>
                        {y.options[y.solution.correctAns - 1] && (
                          <BlockContent
                            blocks={
                              y.options[y.solution.correctAns - 1].optionValue
                            }
                          />
                        )}
                      </div>
                      <div className="">
                        <h1 className="py-2">
                          <strong>Exaplanation: </strong>
                        </h1>
                        <BlockContent blocks={y.solution.explanation} />
                      </div>
                    </div>
                  );
                }
                if (y._type == "genericQuestion")
                  return (
                    <div key={y.id}>
                      <BlockContent blocks={y.questionValue} />
                    </div>
                  );
              });
            })}
        </div>

      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
