import React from "react";

export class HeaderButton {
  constructor(buttonName, link) {
    this.buttonName = buttonName;
    this.link = link;
  }
}

export class HeroHeader {
  constructor(headerButtons, title, author) {
    this.headerButtons = headerButtons;
    this.title = title;
  }
}
export const HeroHeaderSyllabus = (props) => {
  const { headerButtons, title, author } = props.props;
  return (
    <div className=" flex items-center justify-center mt-24 mb-4 lg:mt-32">
      <div className="flex flex-col items-center">
        <span className="flex items-center space-x-4">
          {headerButtons.map((button, index) => (
            <a
              key={index}
              href={button.link}
              className="bg-[#007fff] px-2 py-1 lg;px-4 lg:py-2 rounded-md text-[#FFF] font-semibold text-[10px] sm:text-sm md:text-sm"
            >
              {button.buttonName}
            </a>
          ))}
        </span>
        <h1 className="lg:text-[2.25vw] lg:leading-[4vw] text-lg font-bold text-[#030260] ">
          {title}
        </h1>
        <strong className="text-[#2d2d2d] text-xs lg:text-[1.1vw] font-semibold">
          By Admin
        </strong>
      </div>
    </div>
  );
};
