import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  HeaderButton,
  HeroHeaderSyllabus,
  HeroHeader,
} from "../../HeroHeaderSyllabus";
import { DataContext } from "../../../../context/DataContext";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import Header from "../../../header/Header";
import { SubjectType } from "./SubjectType";

export const SyllabusPage = () => {
  const { exams, loading } = useContext(DataContext);
  const [loaded, setLoaded] = useState(false);
  const { examName, subExamName } = useParams();
  const [fullSyllabus, setFullSyllabus] = useState();
  const [subjectSyllabus, setSubjectSyllabus] = useState();

  let header = {};

  if (loading) {
    return <></>;
  }
  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find((x) => x.title === subExamName);
  const syllabus = subExam.syllabus;
  // console.log("new syl", syllabus);

  let subjectSyl = [];
  let fullSyl = [];

  if (subExam && !loading) {
    subExam.syllabus.items.forEach((item) => {
      if (item._type === "subjectSyllabus") {
        subjectSyl.push(item);
      } else if (item._type === "fullSyllabus") {
        fullSyl.push(item);
      }
    });

    // console.log("full", fullSyl);
    // console.log("subject", subjectSyl);
  }

  if (examName && subExamName && exams && fullSyl) {
    var headerButtons = [
      new HeaderButton(
        `${examName} ${subExamName} Questions`,
        `/${examName}/${subExamName}/questions`
      ),
      new HeaderButton(
        `${examName} ${subExamName} PYQs`,
        `/${examName}/${subExamName}/pyqs`
      ),
    ];
    header = new HeroHeader(
      headerButtons,
      syllabus.title,
      syllabus.author,
      syllabus.date
    );
  }
  if (examName && subExamName && exams && subjectSyl) {
    var headerButtons = [
      new HeaderButton(
        `${examName} ${subExamName} Questions`,
        `/${examName}/${subExamName}/questions`
      ),
      new HeaderButton(
        `${examName} ${subExamName} PYQs`,
        `/${examName}/${subExamName}/pyqs`
      ),
    ];
    header = new HeroHeader(
      headerButtons,
      `${examName} ` + syllabus.title,
      syllabus.author,
      syllabus.date
    );
  }

  return (
    <>
      <Header />
       <div className="mt-[12vw]">
            {exams && header && <HeroHeaderSyllabus props={header} />}
          </div>
      <div className="syllabus-content-page w-full flex items-start gap-5 my-[2vw]">
        <section className="w-full">
          {fullSyl && (
            <SubjectType
              syllabus={fullSyl}
              examname={examName}
              subexamname={subExamName}
              syllabusType={`Full Syllabus`}
            />
          )}
          {subjectSyl && (
            <SubjectType
              syllabus={subjectSyl}
              examname={examName}
              subexamname={subExamName}
              syllabusType={`Subject Syllabus`}
            />
          )}
        </section>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
