import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { urlFor } from "../../sanityCli";
import { DataContext } from "../../context/DataContext";
import { HomeConsultant } from "../reusablepages/pages/contactPage/jsfile/HomeConsultant";
import { Footer } from "../footer/Footer";
import ScrollToTopButton from "../../unusableComponent/utils/scrollToTopButton";
import Header from "../header/Header";

export const AjayAnnurag = () => {
  const { adminInfo, loading } = useContext(DataContext);
  if (loading) return <></>;

  return (
    <>
      <Header />
      <main className="px-[4vw] py-[5vw] lg:my-[7vw] mt-28 mb-12 ">
        <div className="flex lg:flex-row flex-col items-center gap-8 ">
          <section className="w-full lg:w-1/2 flex flex-col items-start space-y-3 lg:space-y-5">
            {adminInfo && (
              <h1 className="text-[#007fff] lg:text-[3.75vw] lg:leading-[5vw] text-xl font-semibold">
                {adminInfo[0].ajayAnuragPage.name}
              </h1>
            )}

            {adminInfo && (
              <p className="text-[#59595A] opacity-90 lg:text-xl text-base font-normal">
                {adminInfo[0].ajayAnuragPage.description}
              </p>
            )}
            {adminInfo && (
              <Link
                to="/UPSC/Prelims/questions"
                className="bg-[#007fff] text-white rounded-full text-center px-8 py-2 hover:scale-x-105 transition-300"
              >
                {adminInfo[0].ajayAnuragPage.buttonText}
              </Link>
            )}
          </section>
          <section className="lg:w-1/2 w-full">
            {adminInfo && (
              <img src={urlFor(adminInfo[0].ajayAnuragPage.image)} alt="/" />
            )}
          </section>
        </div>
        <div className="w-full flex flex-col items-start space-y-2 py-12 lg:py-24">
          {adminInfo && (
            <h1 className="text-[#007fff] lg:text-[3.75vw] font-semibold lg:leading-[5.5vw] text-xl">
              {adminInfo[0].ajayAnuragPage.aboutMe}
            </h1>
          )}
          {adminInfo && (
            <p className="text-[#59595A] lg:text-xl text-base font-normal">
              {adminInfo[0].ajayAnuragPage.aboutText1}
            </p>
          )}
          <div className="w-full flex items-center justify-center">
            {adminInfo && (
              <img
                src={urlFor(adminInfo[0].ajayAnuragPage.image)}
                alt="Dr. Ajay Anurag"
                className="w-4/5 py-10"
              />
            )}
          </div>
          {adminInfo && (
            <p className="text-[#59595A] lg:text-xl text-base font-normal ">
              {adminInfo[0].ajayAnuragPage.aboutText2}
            </p>
          )}
        </div>

        {adminInfo && (
          <p className="text-[#007fff] text-2xl lg:text-3xl font-bold ">
            {adminInfo[0].ajayAnuragPage.achievementTitle}
          </p>
        )}

        <div className="lg:mt-6 my-6 flex flex-col items-start space-y-8 border-b lg:space-y-24">
          {adminInfo &&
            adminInfo[0].ajayAnuragPage.achievements.map(
              (achievement, index) => (
                <section
                  key={index}
                  className="w-full flex lg:flex-row flex-col items-center justify-between space-y-8 lg:space-y-0 lg:gap-20"
                >
                  {index % 2 === 0 ? (
                    <div className="w-full flex lg:flex-row flex-col items-center justify-between space-y-8 lg:space-y-0 lg:gap-20"> 
                      <div className="w-full lg:w-2/5  bg-[#2d2d2d]">
                        <img
                          src={urlFor(achievement.image)}
                          alt={achievement.title}
                          className="w-full h-full object-cover rounded-sm"
                        />
                      </div>
                      <div className="w-full lg:w-3/5 flex flex-col items-start space-y-4 lg:space-y-2">
                        <h1 className="text-[#007fff] text-base lg:text-2xl font-semibold">
                          {achievement.title}
                        </h1>
                        <p className="text-[#007fff] lg:text-xl text-sm font-normal">
                          {achievement.date}
                        </p>
                        <p className="text-[#59595A] text-sm lg:text-xl font-normal">
                          {achievement.description}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full flex lg:flex-row flex-col items-center justify-between space-y-8 lg:space-y-0 lg:gap-20"> 
                      <div className="lg:w-3/5 w-full flex flex-col items-start space-y-2">
                        <h1 className="text-[#007fff] text-base lg:text-2xl font-semibold">
                          {achievement.title}
                        </h1>
                        <p className="text-[#007fff] lg:text-xl text-sm font-normal my-4">
                          {achievement.date}
                        </p>
                        <p className="text-[#59595A] text-sm lg:text-xl font-normal">
                          {achievement.description}
                        </p>
                      </div>
                      <div className="lg:w-2/5 w-full  bg-[#2d2d2d]">
                        <img
                          src={urlFor(achievement.image)}
                          alt={achievement.title}
                          className="w-full h-full object-cover rounded-sm"
                        />
                      </div>
                    </div>
                  )}
                </section>
              )
            )}
        </div>
      </main>
      <HomeConsultant />
      <br />
      <br /> <br />
      <br />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
