import React from "react";

const Table2 = ({ title, data }) => {
  // console.log("table data", data);

  return (
    <>
      <div className="w-full px-2 lg:m-0">
        {title && <h1 className="text-[#030260] text-start text-lg lg:text-[2vw] lg:leading-[3vw] font-semibold mt-4">{title}</h1>}
      </div>
      {data &&
      <table className="w-full border-collapse  lg:mx-2  lg:my-4 " style={{ borderBottom: `2px solid ${data.borderColour ? data.borderColour.hex : "#fff"}` }}>
        <thead style={{ backgroundColor: data.headerColor ? data.headerColor.hex : "#fff" }}>
          <tr>
            {data && data.headers && data.headers.map((header, index) => (
              <th key={index && index} className={`px-4 py-2 text-start text-slate-50 border lg:text-lg text-sm`}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {data && data.rows.map((row, rowIndex) => (
            <tr key={rowIndex && rowIndex} className={`${rowIndex % 2 !== 0 ? 'bg-gray-100' : '' }`}>
              {row.values && row.values.map((value, colIndex) => (
                <td key={colIndex && colIndex} className={`px-4 py-2  lg:text-lg text-sm font-medium text-gray-700 border-2 border-gray-200`}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
}
    </>
  );
};

export default Table2;


