import React, { useState, useEffect } from "react";
import icon1 from "../assets/sIcon1.svg";
import icon2 from "../assets/sIcon2.svg";
import icon3 from "../assets/sIcon3.svg";
import icon4 from "../assets/sIcon4.svg";
import client, { urlFor } from "../../../../../sanityCli";
import { v4 as uuid4 } from "uuid";


export const HomeConsultant = () => {
  const [content, setContent] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    emailId: "",
    phoneNumber: "",
    question: "",
  });

  const fetchData = async () => {
    try {
      const result = await client.fetch('*[_type == "contactSection"][0]');
      setContent(result);
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch the quesAns document to append the new comment
    const quesAnsDoc = await client.fetch('*[_type == "quesAns"][0]');

    client
      .patch(quesAnsDoc._id)
      .setIfMissing({ commentText: [] })
      .append("commentText", [
        {
          question: formData.question,
          name: formData.fullName,
          email: formData.emailId,
          phone: formData.phoneNumber,
          time: new Date().toISOString(),
          _key: uuid4(),
          answer: "", // Answer will be empty initially
        },
      ])
      .commit()
      .then(() => {
        alert("Form submitted successfully!");
        setFormData({
          fullName: "",
          emailId: "",
          phoneNumber: "",
          question: "",
        });
      });
  };

  if (!content) return <div></div>;

  return (
    <>
      <div id="contact" className="contact-section p-[1%] ">
        <div className="w-full flex flex-col items-center space-y-5 pb-3 lg:pb-8">
          <p className="text-[#007fff] underline font-montserrat font-semibold text-2xl text-center lg:text-start lg:text-4xl pr-0 lg:pr-20">
            {content.mainTitle}
          </p>
        </div>
        <div className="flex lg:flex-row flex-col items-start gap-8 p-[2%] bg-gray-200 rounded-md">
          <div className="w-full lg:w-1/2 relative flex items-center justify-center">
           {content && content.image && <img src={urlFor(content.image)} alt="/" className="lg:h-[32vw] w-full "/>}
          </div>
          <div className="w-full lg:w-1/2 bg-[#FFF] px-[4%] lg:py-5 py-[5%] flex flex-col items-center lg:items-start space-y-8">
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="w-full flex flex-wrap items-center gap-4">
                <span className="w-full lg:w-[46%] flex flex-col items-start space-y-2">
                  <label className="text-[#000] text-sm font-montserrat font-medium">
                    {content.formLabels.fullName}
                  </label>
                  <input
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    className="first-name bg-[#FAFAFA] rounded-sm h-10 w-full px-4 text-[#4A154B] text-sm font-normal font-montserrat placeholder-[#4A154B] placeholder-opacity-50"
                    placeholder="Your name"
                  />
                </span>
                <span className="w-full lg:w-[46%] flex flex-col items-start space-y-2">
                  <label className="text-[#000] text-sm font-montserrat font-medium">
                    {content.formLabels.emailId}
                  </label>
                  <input
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleInputChange}
                    className="first-name bg-[#FAFAFA] rounded-sm h-10 w-full px-4 text-[#4A154B] text-sm font-normal font-montserrat placeholder-[#4A154B] placeholder-opacity-50"
                    placeholder="Your Email"
                  />
                </span>
                <span className="w-full lg:w-[46%] flex flex-col items-start space-y-2">
                  <label className="text-[#000] text-sm font-montserrat font-medium">
                    {content.formLabels.phoneNumber}
                  </label>
                  <input
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="first-name bg-[#FAFAFA] rounded-sm h-10 w-full px-4 text-[#4A154B] text-sm font-normal font-montserrat placeholder-[#4A154B] placeholder-opacity-50"
                    placeholder="Your Phone Number"
                  />
                </span>
                <span className="w-full flex flex-col items-start space-y-2">
                  <label className="text-[#000] text-sm font-montserrat font-medium">
                    {content.formLabels.question}
                  </label>
                  <textarea
                    name="question"
                    value={formData.question}
                    onChange={handleInputChange}
                    className="first-name bg-[#FAFAFA] rounded-sm h-40 w-full p-4 text-[#4A154B] text-sm font-normal font-montserrat placeholder-[#4A154B] placeholder-opacity-50"
                    placeholder="Your question"
                  ></textarea>
                </span>
                <button
                  type="submit"
                  className="bg-[#007fff] text-white font-montserrat font-medium text-lg py-1 px-6 rounded-full mt-4"
                >
                  {content.submitButton}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
