import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import client from "../../sanityCli";
import BlockContent from "@sanity/block-content-to-react";

export const About = () => {
  const [aboutData, setAboutData] = useState(null);
  useEffect(() => {
    client.fetch(
      `*[_type == "about"]{
        title,
        tagline,
        description1,
        description2,
      }`
    )
    .then((data) => {
      // console.log("about data", data);
      // console.log(data[0]);
      setAboutData(data[0]);
    })
    .catch(console.error);
  }, []);

  if (!aboutData) {
    return <div></div>;
  }

  return (
    <>
      <Header />
      <div className="flex flex-col items-center lg:justify-center justify-start space-y-6">
        <p className="text-[#007fff] text-xl lg:text-4xl font-semibold">{aboutData.title}</p>
        <p className="lg:text-[1.5vw] lg:leading-[2.25vw]">{aboutData.tagline}</p>
        <div className="text-center">
          <BlockContent blocks={aboutData.description1} />
        </div>
        <div className="mt-8 text-center">
          <BlockContent blocks={aboutData.description2} />
        </div>
        {/* <p>{aboutData.author}</p>
        <button>{aboutData.ctaText}</button> */}
      </div>
    </>
  );
};









// import React, { useEffect, useState } from "react";
// import Header from "../../header/Header";
// import { Footer } from "../../footer/Footer";
// import ScrollToTopButton from "../../../utils/scrollToTopButton";
// import client from "../../../sanityCli";

// export const About = () => {
//   const [aboutData, setAboutData] = useState(null);

//   useEffect(() => {
//     client.fetch(
//       `*[_type == "about"]{
//         title,
//         tagline,
//         description1,
//         description2,
//         author,
//         ctaText
//       }`
//     ).then((data) => {
//         console.log("about data", data)
//         console.log(data[0])
//       setAboutData(data[0]);
//     }).catch(console.error);
//   }, []);

//   if (!aboutData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <Header />
//       <div className="flex flex-col items-center space-y-8 py-[3vw] px-[4vw]">
//         <p className="text-blue-500 text-[2vw] font-serif font-medium">{aboutData.title}</p>
//         <p>{aboutData.tagline}</p>
       
//         <p>{aboutData.description1}</p>
//         <p>{aboutData.description2}</p>
//         <p>{aboutData.author}</p>
//         <button>{aboutData.ctaText}</button>
//       </div>
//       <Footer />
//       <ScrollToTopButton />
//     </>
//   );
// };
