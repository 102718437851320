import React, { useState, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { urlFor } from "../../sanityCli";

export const AjayAnuragWritten = () => {
  const { adminInfo, loading } = useContext(DataContext);
  if (loading) <></>;

  return (
    <div className="ajay-anurag-section w-full px-2 py-1 lg:px-[15vw] z-50 flex items-center justify-center">
      <div
        className="w-[96%] bg-[#fff]  md:w-[95%] flex md:flex-row flex-col items-center justify-center py-6 lg:gap-6 lg:px-10 lg:py-8 rounded-xl transition-all duration-500 ease-in-out shadow-xl hover:shadow-2xl translate-y-1 hover:transform lg:hover:-translate-y-3"
        // style={{ background: navbarColor }}
      >
        <div className="w-1/3 md:w-1/4 flex items-center justify-center">
          {adminInfo && (
            <img
              src={urlFor(adminInfo[0].homeSection.image)}
              alt="/image"
              className="md:w-[90%] lg:w-[90%] w-full rounded-full"
            />
          )}
        </div>
        <div className="w-full lg:w-3/4 flex flex-col items-center space-y-2 lg:space-y-6 lg:items-start justify-center lg:p-0 p-4">
          {adminInfo && (
            <h1 className="text-[#007fff] text-xl text-center lg:text-start lg:text-[2.75vw] font-bold">
              {adminInfo[0].homeSection.title}{" "}
              <span className="text-[#FF2C45]">
                {adminInfo[0].homeSection.highlightedText}
              </span>
            </h1>
          )}
          <ul className="flex flex-col ml-2 items-center justify-center text-center space-y-2 lg:space-y-4">
            {adminInfo[0].homeSection &&
              adminInfo[0].homeSection.bulletPoint.map((item, index) => (
                <li key={index} className="w-full flex items-start gap-2">
                     <span className="w-[2px] h-[2px] lg:w-1 lg:h-1 rounded-full bg-[#007fff] p-1 mt-1"></span>
                  {item && (
                    <span className="lg:text-sm text-[10px] md:text-xs text-[#2D2D2D] opacity-70 font-semibold lg:text-start text-center">
                      {item}
                    </span>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

          {/* {adminInfo && (
            <Link
              to="/ajay/anurag"
              className="bg-[#030260] text-[#FFF] font-medium text-base lg:text-sm flex items-center justify-center  px-4 py-2 rounded-full"
            >
              <strong>संपर्क करें</strong>
            </Link>
          )} */}
