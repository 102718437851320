import React from 'react'
import { About } from './About'
import ScrollToTopButton from '../../unusableComponent/utils/scrollToTopButton'
import Header from '../header/Header'
import { Footer } from '../footer/Footer'

export const AboutPage = () => {
  return (
    <div >
         <Header />
         <div className='lg:h-[60vw] min-h-96 flex justify-center items-center my-24 lg:m-0'>
         <About />
         </div>
         <Footer />
         <ScrollToTopButton />
    </div>
  )
}
