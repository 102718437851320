import React from "react";
import { useNavigate } from "react-router-dom";
import { urlFor } from "../../../../sanityCli";

export const SubjectType = ({
  syllabus,
  examname,
  subexamname,
  syllabusType,
}) => {

  // console.log("syllabus", syllabus);
  // if (syllabusType == "Subject Syllabus") {
  //   console.log("subject syllabus", syllabus[0].title);
  // }
  // if (syllabusType == "Full Syllabus") {
  //   console.log("Full syllabus", syllabus);
  // }
  // console.log('syllabusss', syllabus)

  const navigate = useNavigate();
  return (
    <div>
      {syllabusType == "Full Syllabus" && (
        <div className="w-full flex flex-col items-center justify-center">
          <section className="w-full px-[4vw] py-[1vw] flex flex-col items-center space-y-4 my- lg:my-0">
            <h1 className="text-[#007fff] lg:text-[2.25vw] lg:leading-[5vw] text-xl font-semibold">
              {examname} {subexamname} {syllabusType}
            </h1>
            <div className="w-full flex flex-wrap items-start justify-between">
              {syllabus.map((x, i) => {
                return (
                  <div
                    onClick={() =>
                      navigate(
                        `/${examname}/${subexamname}/syllabus/${x.title}`,
                        { state: { syllabus, syllabusType } }
                      )
                    }
                    className="w-full sm:w-[45%] lg:w-[30%] bg-white rounded-lg  px-4 py-2 lg:px-4 lg:py-2 flex items-center gap-4 mb-6 transition-all duration-500 ease-in-out shadow-xl hover:shadow-2xl hover:transform hover:-translate-y-1"
                  >
                    <span className="rounded-full flex md:flex-row flex-col items-center justify-center">
                     {x && x.icon &&<img
                        src={urlFor(x.icon)}
                        alt="/icon"
                        className="w-20 md:w-24 lg:w-[6vw]"
                      />}
                    </span>
                    <span className="flex flex-col items-start space-y-2 lg:space-y-3">
                      <strong className="lg:text-[1.2vw] lg:leading-[2vw] text-base">{x.title}</strong>
                      <span className="lg:text-[1.25vw] text-xs">{syllabusType}</span>
                    </span>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      )}

      {syllabusType == "Subject Syllabus" && (
        <div className="w-full flex flex-col items-center justify-center">
          <section className="w-full px-[4vw] pb-20 lg:pb-[4vw] pt-[2vw] flex flex-col items-center ">
            <h1 className="text-[#007fff] lg:text-[2.25vw] lg:leading-[5vw] text-xl font-semibold ">
              {examname} {subexamname} {syllabusType}
            </h1>
            <div className="w-full flex flex-wrap items-start justify-between">
              {syllabus.map((x, i) => {
                return (
                  <div
                    onClick={() =>
                      navigate(
                        `/${examname}/${subexamname}/syllabus/${x.title}`,
                        { state: { syllabus, syllabusType } }
                      )
                    }
                    className="sm:w-[45%] lg:w-[30%] w-full bg-white rounded-lg px-4 py-2  lg:px-4 lg:py-2 flex items-center gap-2 lg:gap-4 mt-3 transition-all duration-500 ease-in-out shadow-xl hover:shadow-2xl hover:transform hover:-translate-y-1"
                  >
                    <span className="rounded-full flex items-center justify-center">
                    {x && x.icon && <img
                        src={urlFor(x.icon)}
                        alt="/icon"
                        className="w-20 md:w-24 lg:w-[6vw]"
                      />}
                    </span> 
                    <span className="flex flex-col items-start space-y-2">
                      <strong className="lg:text-[1.25vw] lg:leading-[1.65vw] text-base">{x.title}</strong>
                      <span className="lg:text-[1.25vw] text-xs">{syllabusType}</span>
                    </span>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};
