import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import Header from "../../../header/Header";
import { HeroHeader, HeaderButton, HeroHeaderSyllabus } from "../../HeroHeaderSyllabus";
import { SectionHeader } from "../questionPage/Section";
import { RightSideBar } from "../../../rightSideBar/RightSideBar";

export const PyqDetailPage = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName, title } = useParams();
  let header = {};
  if (loading) return <></>;

  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const syllabus = subExam.syllabus;
  const questions = subExam.pyq;
  // console.log("questions", subExam);
  let Questions = null;
  Questions = questions.items.filter((x) => x.title === title);
  if (examName && subExamName && exams && syllabus) {
    var headerButtons = [
      new HeaderButton(`Exam notes`, `/notes`),
      new HeaderButton(
        `${examName} ${subExamName} PYQs`,
        `/${examName}/${subExamName}/pyqs`
      ),
    ];
    header = new HeroHeader(
      headerButtons,
      examName + " " + subExamName + " Questions",
      syllabus.author,
      syllabus.date
    );
  }

  // console.log("questions", questions);
  return (
    <>
      <Header />
      <div className="mt-4">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <main className="flex items-start gap-8 lg:my-10 lg:px-[4vw]">
      <div className="w-full lg:w-[75%] bg-[#FFF] flex flex-col items-start rounded-sm mb-8 p-4 lg:p-8 overflow-y-auto">
          {title && (
            <h1 className="lg:text-[2vw] lg:leading-[3.25vw] text-lg text-[#007fff] font-extrabold capitalize">
              {title} Questions in {examName} {subExamName}
            </h1>
          )}
          {!title && (
            <h1 className="lg:text-[2vw] lg:leading-[3.25vw] text-lg text-[#007fff] font-extrabold capitalize">
              Full Questions in {examName} {subExamName}
            </h1>
          )}
          {Questions &&
            Questions.map((x) => {
              return x.items.map((y) => {
                if (y._type === "sectionHeading") {
                  return <SectionHeader value={y.value} />;
                }
                if (y._type === "mcq") {
                  return (
                    <div
                      key={y.id}
                      className="w-full flex flex-col items-start  rounded-sm  lg:px-4"
                    >
                      <div className="text-base font-normal text-[#2d2d2d]">
                        <BlockContent blocks={y.question} />
                      </div>
                      <div className="lg:my-2">
                        {y.options.map((z) => {
                          return (
                            <BlockContent key={z.id} blocks={z.optionValue} />
                          );
                        })}
                      </div>
                      <div className="my-2 flex flex-col items-start space-y-3">
                        <strong>Correct Answer: </strong>
                        {y.options[y.solution.correctAns - 1] && (
                          <BlockContent
                            blocks={
                              y.options[y.solution.correctAns - 1].optionValue
                            }
                          />
                        )}
                      </div>
                      <div className="">
                        <h1 className="py-2">
                          <strong>Exaplanation: </strong>
                        </h1>
                        <BlockContent blocks={y.solution.explanation} />
                      </div>
                    </div>
                  );
                }
                if (y._type === "genericQuestion")
                  return (
                    <div key={y.id}>
                      <BlockContent blocks={y.questionValue} />
                    </div>
                  );
              });
            })}
        </div>
        <div className="hidden lg:block lg:w-[25%] sticky top-32 h-fit">
          <RightSideBar
            examName={examName}
            subExamName={subExamName}
            // title={title}
            // // topic={topic}
          />
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};


