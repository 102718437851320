import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import Header from "../../../header/Header";
import { urlFor } from "../../../../sanityCli";

export const Pyq = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName } = useParams();
  const navigate = useNavigate();
  if (loading) {
    return <div></div>;
  }
  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const questions = subExam.pyq;
  // console.log("pyqs", questions);
  const subjectQuestions = questions.items.filter(
    (x) => x._type === "nSubject"
  );
  const fullQuestions = questions.items.filter((x) => x._type === "full");

  const subjectTitles = subjectQuestions.map((question) => question.title);
  const fullTitles = fullQuestions.map((question) => question.title);

  // console.log("subject questions", subjectQuestions);
  // console.log("subject title", subjectTitles);

  return (
    <>
      <Header />
      <div className="mt-20">  
        <h1 className="text-[#007fff] text-xl lg:text-[2.25vw] lg:leading-[4vw] font-semibold  py-[3vw] text-center underline lg:pb-0">
          {examName} {subExamName} PYQs
        </h1>
        <section className="w-full px-[4vw] lg:py-[4vw] flex flex-col items-start sm:items-center space-y-2">
          <h1 className="text-[#007fff]  text-xl sm:text-xl lg:text-[2.25vw] lg:leading-[4vw] font-semibold">
            {examName} {subExamName} Full question Papers
          </h1>
          <div className="w-full flex flex-wrap items-start justify-between">
            {fullTitles.map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() =>
                    navigate(`/${examName}/${subExamName}/full/pyqs`, {
                      state: { questions, x },
                    })
                  }
                  className="w-full sm:w-[46%] lg:w-[24%] bg-white rounded-lg shadow-lg px-4 py-2 flex items-center gap-2 lg:gap-2 mb-6 lg:m-0 transition-all duration-500 ease-in-out  hover:shadow-2xl hover:transform hover:-translate-y-2"
                >
                  <span className=" rounded-full flex items-center justify-center">
                    <img src={urlFor(fullQuestions.find((z) => z.title === x ).icon)} alt="/icon" className="w-24 sm:w-24 lg:w-[5vw]" />
                  </span>
                  <span className="flex flex-col items-start space-y-1 lg:space-y-2">
                    <strong className="lg:text-xl text-lg">{x}</strong>
                    <span className="lg:text-base text-sm">{x}</span>
                  </span>
                </div>
              );
            })}
          </div>
        </section>
      </div>

      <div>
        <section className="w-full px-[4vw] py-[4vw] pb-[8vw]  flex flex-col items-start sm:items-center space-y-2">
            <h1 className="text-[#007fff] text-xl lg:text-[2.25vw] lg:leading-[4vw] font-semibold ">
              {examName} {subExamName} subject wise PYQs
            </h1>
            <div className="w-full flex flex-wrap items-start justify-between">
              {subjectTitles.map((x, i) => {
                return (
                  <div
                    key={i}
                    onClick={() =>
                      navigate(`/${examName}/${subExamName}/${x}/pyqs`, {
                        state: { questions, x },
                      })
                    }
                    className="w-full sm:w-[46%] lg:w-[24%] bg-white rounded-lg shadow-lg px-4 py-2 flex items-center gap-2 mb-5 transition-all duration-500 ease-in-out  hover:shadow-2xl hover:transform hover:-translate-y-2"
                  >
                    <span className="rounded-full flex items-center justify-center">
                      <img src={urlFor(subjectQuestions.find((z) => z.title === x ).icon)} alt="/icon" className="w-24 lg:w-[5vw]" />
                    </span>
                    <span className="flex flex-col items-start space-y-1">
                      <strong className="lg:text-xl text-lg">{x}</strong>
                      <span className="lg:text-base text-sm">{x}</span>
                    </span>
                  </div>
                );
              })}
            </div>
        </section>
      </div>

      <Footer />
      <ScrollToTopButton />
    </>
  );
};
