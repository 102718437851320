// PrivacyPolicy.js
import React, { useContext, useEffect, useState } from 'react';
import Header from "../header/Header";
import { Footer } from "../footer/Footer";
import { DataContext } from '../../context/DataContext';

export const TermServices = () => {
  const { termsPolicy, loading} = useContext(DataContext)

  if (loading) return <div></div>;

  return (
    <>
      <Header />
      <div className="terms_Services mt-36 lg:mt-40">
        {termsPolicy && <h1 className="text-center text-[#030260] text-xl lg:text-3xl font-semibold ">
          {termsPolicy[0].heading}
        </h1>}
        <div className="privacy-content w-full flex items-start gap-8 lg:my-20 lg:px-8">
          <div className="rounded-md shadow-sm p-6">
            {termsPolicy && <p className="mb-4 text-[#030260] text-lg lg:text-2xl">{termsPolicy[0].title}</p>}
            {termsPolicy[0].subTitle && termsPolicy[0].subTitle.map((item, index) => (
              <div key={index} className=" mb-4">
                <li className="list-inside rounded-full lg:mt-2 text-base lg:text-lg pl-2 lg:px-4">{item}</li>
              </div >
            ))}
            <div>
              {termsPolicy.listText && termsPolicy[0].listText.map((item, index) => (
                <p key={index} className="text-base lg:text-lg">
                  {item}
                </p>
              ))}
            </div>
            <div className='py-3'>
              {termsPolicy[0].topic && termsPolicy[0].topic.map((topic, index) => (
                <div key={index} className="mb-6 my-4">
                  <p className="font-bold text-[#030260] text-base lg:text-2xl pb-6">{topic.heading}</p>
                  <ul className="list-disc flex flex-col items-start space-y-3 pl-2 lg:px-8">
                    {topic.subHeading.map((sub, subIndex) => (
                      <li className="rounded-full text-base lg:text-lg px-4">
                        {sub}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="my-8">
              {termsPolicy[0].generalText && termsPolicy[0].generalText.map((item, index) => (
                <div key={index} className="">
                  <p className="font-bold text-[#030260] text-lg lg:text-2xl">{item.heading}</p>
                  {item.subheading.map((sub, subIndex) => (
                    <p key={subIndex} className="mt-4 text-[1.25vw] leading-[2.5vw]">
                      {sub}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            <div className="my-8 border-b border-[#6664b8] pb-6">
              {termsPolicy[0].disclaimer && termsPolicy[0].disclaimer.map((item, index) => (
                <div key={index} className="">
                  <p className="font-bold text-[#030260] text-lg lg:text-2xl">{item.heading}</p>
                  {item.subheading.map((sub, subIndex) => (
                    <li key={subIndex} className="mt-4 text-2xl lg:text-lg">
                      {sub}
                    </li>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};





// import React from "react";
// import Header from "../../header/Header";
// import { Footer } from "../../footer/Footer";
// import { RightSideBar } from "../../rightSideBar/RightSideBar";

// const privacyData = [
//   { heading: "Privacy Policy" },
//   {
//     title: "Throughout this document,",
//     subTitle: [
//       "The terms “We” / “Us”/ “Our”/ “Company”/ “Website” / “Websites” individually and collectively refer to “HindiArise” or “Hindi Arise” [hindiarise.com] and the terms “You” /”Your” / “Yourself” refer to the “User” / “Customer” / “Subscriber” of Hindi Arise [www.hindiarise.com]",
//       "The term “Hindi Arise” refers to all the websites that use “HindiArise.com” as the root domain.",
//       "The term “Hindi Arise” refers to the website pointed by the web address “www.hindiarise.com”.",
//       "The term HindiArise collectively refers to Hindi Arise (www.hindiarise.com).",
//     ],
//   },
//   {
//     listText: [
//       "This document is published and shall be construed in accordance with the provisions of the Information Technology (Reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of sensitive personal data or information.",
//     ],
//   },
//   {
//     topic: [
//       {
//         heading:
//           "What personal information do we collect from the people that use the services of Hindi Arise or visit our websites?",
//         subHeading: [
//           "What personal information do we collect from the people that use the services of Hindi Arise or visit our websites?",
//         ],
//       },
//     ],
//   },
//   {
//     generalText: [
//       {
//         heading: "Grievance Redressal Mechanism",
//         subheading: [
//           "Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the Admin, Hindi Arise as mentioned below via in writing or through email signed with the electronic signature to Admin, Hindi Arise.",
//           "If you have any questions please feel free to contact us at our contact us page or you can mail us at ‘ajayanurag@gamil.com’",
//         ],
//       },
//     ],
//   },
//   {
//     disclaimer: [
//       {
//         heading: "Grievance Redressal Mechanism",
//         subheading: [
//           "Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the Admin, Hindi Arise as mentioned below via in writing or through email signed with the electronic signature to Admin, Hindi Arise.",
//           "If you have any questions please feel free to contact us at our contact us page or you can mail us at ‘ajayanurag@gamil.com’",
        
//         "Thanks!", "AjayAnurag(www.ajayanurag.com)"],
//       },
//     ],
//   },

// ];

// export const PrivacyPolicy = () => {
//   return (
//     <>
//       <Header />
//       <div className="privacy-policy bg-blue-50">
//         <h1 className="text-center text-blue-500 text-[2vw] font-mono font-semibold py-8">
//           {privacyData[0].heading}
//         </h1>
//         <div className="privacy-content w-full flex items-start gap-8 my-20 px-8">
//           <div className="bg-slate-50 w-3/4 rounded-md shadow-sm p-6">
//             <p className="mb-4">{privacyData[1].title}</p>
//             {privacyData[1].subTitle.map((item, index) => (
//               <div key={index} className="flex items-start px-4 gap-2 mb-4">
//                 <span className="w-2 h-2 bg-blue-500 rounded-full mt-2"></span>
//                 <p>{item}</p>
//               </div>
//             ))}
//             <div>
//               {privacyData[2].listText.map((item, index) => (
//                 <p key={index} className="mb-5">
//                   {item}
//                 </p>
//               ))}
//             </div>
//             <div>
//               {privacyData[3].topic.map((topic, index) => (
//                 <div key={index} className="mb-6 my-8">
//                   <p className="font-bold">{topic.heading}</p>
//                   <ul className="list-none flex flex-col items-start space-y-5">
//                     {topic.subHeading.map((sub, subIndex) => (
//                       <li key={subIndex} className="flex items-center gap-2 mt-4">
//                         <span className="w-2 h-2 bg-[#1E3A8A] rounded-full"></span>
//                         <span>{sub}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               ))}
//             </div>
//             <div className="my-8">
//               {privacyData[4].generalText.map((item, index) => (
//                 <div key={index} className="">
//                   <p className="font-bold">{item.heading}</p>
//                   {item.subheading.map((sub, subIndex) => (
//                     <p key={subIndex} className="mt-4">
//                       {sub}
//                     </p>
//                   ))}
//                 </div>
//               ))}
//             </div>
//             <div className="my-8 border-b-2 border-blue-500 pb-6">
//               {privacyData[5].disclaimer.map((item, index) => (
//                 <div key={index} className="">
//                   <p className="font-bold">{item.heading}</p>
//                   {item.subheading.map((sub, subIndex) => (
//                     <p key={subIndex} className="mt-4">
//                       {sub}
//                     </p>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="w-1/4">
//             <RightSideBar />
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };
