import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "../../sanityCli";

export const ListContent = (listContents) => {
  // console.log("content", listContents.listContents);
  return (
    <div className="w-full">
      {listContents.listContents.map((topic, index) => {
        switch (topic.listStyle) {
          case "plain":
            return (
              <div className="lg:p-4 p-2">
                <strong className="lg:text-[2vw] lg:leading-[3vw]  md:text-xl text-lg  font-semibold text-[#030260]  capitalize">
                  {topic.header}
                </strong>
                <ul className="pl-2 mt-2 list-none">
                  {topic.contentList.map((sub, i) => (
                    <li key={i} className="my-2 lg:text-lg text-sm text-[#2d2d2d]">
                      <BlockContent key={i} blocks={sub.content} />
                    </li>
                  ))}
                </ul>
                <div className="w-full flex flex-col items-center space-y-8 rounded-md">
                  {topic.images &&
                    topic.images.map((x) => (
                      <img src={urlFor(x)} className="w-auto mt-4 rounded-lg" alt='/image'/>
                    ))}
                </div>
              </div>
            );
          case "bullet":
            return (
              <div className="lg:p-4 p-2">
                <strong className="lg:text-[2vw] lg:leading-[3vw] text-lg md:text-xl  font-semibold text-[#030260] capitalize">
                  {topic.header}
                </strong>
                <ul className="flex flex-col items-center">
                  {topic.contentList.map((sub, i) => (
                    <li
                      key={i}
                      className="my-2 lg:text-lg text-sm text-[#2d2d2d] list-disc ml-4"
                    >
                      <BlockContent blocks={sub.content} />
                    </li>
                  ))}
                </ul>
                <div className="w-full flex flex-col items-center justify-center space-y-8 ">
                  {topic.images &&
                    topic.images.map((x) => (
                      <img src={urlFor(x)} className="w-auto mt-5 rounded-md" alt='/image'/>
                    ))}
                </div>
              </div>
            );
          case "roman":
            return (
              <div className="lg:p-4 p-2">
                <strong
                  className={`lg:text-[2vw] lg:leading-[3.25vw] text-lg md:text-xl  font-semibold text-[#030260] my-12 capitalize`}
                  style={{ color: topic.headerColor.hex }}
                >
                  {topic.header}
                </strong>
                <ul className="flex flex-col items-start list-[upper-roman]">
                  {topic.contentList.map((sub, i) => (
                    <li
                      key={i}
                      className="my-2 lg:text-lg text-sm text-[#2d2d2d] lg:ml-8 ml-4"
                    >
                      <BlockContent blocks={sub.content} />
                    </li>
                  ))}
                </ul>
                {topic.images &&
                  topic.images.map((x) => (
                    <div className="w-full flex flex-col items-center justify-center space-y-8 mt-4 mx-auto">
                      <img src={urlFor(x)} className="w-auto  rounded-md" alt='/image'/>
                    </div>
                  ))}
              </div>
            );
          case "numbered":
            return (
              <div className="lg:p-4 p-2">
                <strong className="lg:text-[2.5vw] lg:leading-[4.5vw] text-base sm:text-xl  font-semibold text-[#030260]  capitalize">
                  {topic.header}
                </strong>
                <ul className="flex flex-col items-start">
                  {topic.contentList.map((sub, i) => (
                    <li key={i} className="my-2 lg:text-lg text-sm text-[#2d2d2d] lg:ml-8 ml-4 list-decimal">
                      <BlockContent blocks={sub.content} />
                    </li>
                  ))}
                </ul>
                <div className="w-full flex flex-col items-center justify-center rounded-md">
                  {topic.images &&
                    topic.images.map((x) => (
                      <img src={urlFor(x)} className="w-auto rounded-lg my-5" alt='/image'/>
                    ))}
                </div>
              </div>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};
