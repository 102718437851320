import React, { useContext } from "react";
import Header from "../header/Header";
import HomeHero from "../home/HomeHero";
import { AjayAnuragWritten } from "../home/AjayAnuragWritten";
import { HomeAnnouncementSec } from "../home/HomeAnnouncementSec";
import { Footer } from "../footer/Footer";
import ScrollToTopButton from "../../unusableComponent/utils/scrollToTopButton";
import { HomeNotes } from "../home/HomeNotes";
import { HomePracticeSec } from "../home/HomePracticeSec";
import { HomeVideo } from "../home/HomeVideo";
import { HomeConsultant } from "../reusablepages/pages/contactPage/jsfile/HomeConsultant";
import { DataContext } from "../../context/DataContext";
import ThemeToggleButton from "../../context/DarkMode";

export const Home = () => {
  const {bgColor, practiceQuestion, notes, exams, ebooks, blogs, story, homeHero, homeHeroSubjects, questions, adminInfo,privacyPolicy, termsPolicy, video, loading, } = useContext(DataContext)
  if(loading)
    return<></>
  return (
    <>
      <Header />
      <HomeHero />
      <HomeAnnouncementSec />
      <HomePracticeSec />
      {/* <HomeNotes /> */} 
      <HomeVideo />
      <div className="mt-5 lg:mb-16">
        <HomeConsultant />
        </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
