// src/sanityClient.js
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { buildFileUrl } from '@sanity/asset-utils';

const client = sanityClient({
    projectId: 'l955dneo', // find this at manage.sanity.io or in your sanity.json
    dataset: 'production', // this is from those question during 'sanity init'
    useCdn: true, // `false` if you want to ensure fresh data
    token: process.env.REACT_APP_SANITY_TOKEN,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
    return builder.image(source);
};
export const urlForFile = (source) => {
  return buildFileUrl(source, {
    projectId: 'l955dneo', // replace with your project ID
    dataset: 'production' // replace with your dataset name
  });
};

export default client;


export const fetchUpscQuestionData = async () => {
  const query = `*[_type == "upscQuestion"]{
    id,
    subject,
    medium,
    questions[]{
      question,
      options,
      "imageUrl": image.asset->url
    }
  }`;
  return await client.fetch(query);
};

export const fetchUppscQuestionData = async () => {
  const query = `*[_type == "uppscQuestion"]{
    id,
    subject,
    medium,
    sections[]{
      name,
      questions[]{
        number,
        description,
        subQuestions
      }
    }
  }`;
  return await client.fetch(query);
};

// Fetch the blog collection
export const fetchBlogCollection = async () => {
    const query = `*[_type == "blogCollection"]{
      title,
      blogs[]->{
        bandText,
        heading,
        author,
        date,
        designType,
        design1Data,
        design2Data
      }
    }`;
    
    const data = await client.fetch(query);
    return data;
  };
