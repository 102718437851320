// import ;

export function createGradient5Colors(color1, color2, color3, color4, color5) {
    return `linear-gradient(to right, ${color1}, ${color2}, ${color3}, ${color4}, ${color5})`;
}


export function createGradient4Colors(color1, color2, color3, color4) {
    return `linear-gradient(to right, ${color1}, ${color2}, ${color3}, ${color4})`;
}



export function createGradient3Colors(color1, color2, color3) {
    return `linear-gradient(to right, ${color1}, ${color2}, ${color3})`;
}


