import React from "react";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import { useLocation } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import { HeaderButton, HeroHeader, HeroHeaderSyllabus } from "../../HeroHeaderSyllabus";
import { RightSideBar } from "../../../rightSideBar/RightSideBar";

export const PracticeDetail = () => {
  const location = useLocation();
  const Question = location.state;

  const headerButtons = [
    new HeaderButton(`Notes`, `/notes`),
    new HeaderButton(`Blogs`, `/blog`),
  ];
  const header = new HeroHeader(headerButtons, "Daily Practice Questions", "Admin");

  return (
    <div>
      <Header />
      <HeroHeaderSyllabus props={header} />
      <main className="flex items-start gap-8 lg:my-10 lg:px-[4vw] px-2 md:px-4">
        <div className="w-full lg:w-[75%] bg-[#FFF] flex flex-col items-start rounded-sm mb-8 p-4 lg:p-8 overflow-y-auto">
          {Question._type === "mcq" && (
            <>
              <div className="w-full text-base font-normal text-[#2d2d2d]">
                <BlockContent blocks={Question.questionValue} />
              </div>
              <div className="lg:my-2">
                {Question.answerDetail.map((answer, index) => (
                  <BlockContent key={index} blocks={answer.answerValue} />
                ))}
              </div>
              <div className="my-2 flex flex-col items-start space-y-3">
                <strong>Correct Answer: </strong>
                {Question.answerDetail[Question.solution.correctAns - 1] && (
                  <BlockContent
                    blocks={
                      Question.answerDetail[Question.solution.correctAns - 1].answerValue
                    }
                  />
                )}
              </div>
              <div>
                <h1 className="py-2">
                  <strong>Explanation: </strong>
                </h1>
                <BlockContent blocks={Question.solution.explanation} />
              </div>
            </>
          )}

          {Question._type === "practiceGeneric" && (
            <div className="w-full rounded-lg flex flex-col items-start space-y-4">
              <div className="flex flex-col items-start space-y-2 text-lg w-full border-b border-gray-950 pb-4">
                <strong>Question:</strong>
                <BlockContent blocks={Question.questionValue} />
              </div>
              <div className="flex flex-col items-start space-y-4 text-lg w-full pb-4">
                <strong>Answer:</strong>
                {Question.answerDetail.map((answer, index) => (
                  <div key={index}>
                    <BlockContent blocks={answer.answerValue} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Right Section (Sticky Sidebar) */}
        <div className="hidden lg:block lg:w-[25%] sticky top-28 h-fit">
          <RightSideBar
            examName="UPSC"
            subExamName="Prelims"
            title="Geography"
            topic="Landforms"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};


// import React from "react";
// import Header from "../../../header/Header";
// import { Footer } from "../../../footer/Footer";
// import { useLocation } from "react-router-dom";
// import BlockContent from "@sanity/block-content-to-react";
// import {
//   HeaderButton,
//   HeroHeader,
//   HeroHeaderSyllabus,
// } from "../../HeroHeaderSyllabus";
// export const PracticeDetail = () => {
//   const location = useLocation();
//   console.log(location);
//   const Question = location.state;
//   var headerButtons = [
//     new HeaderButton(`Notes`, `/notes`),
//     new HeaderButton(`Blogs`, `/blog`),
//   ];
//   var header = new HeroHeader(
//     headerButtons,
//     "Daily Practice Questions",
//     "Admin"
//   );
//   return (
//     <div className="">
//       <Header />
//       <div className="lg:mt-4"></div>
//       <HeroHeaderSyllabus props={header} />
//       {Question._type == "mcq" && (
//         <div
//           key={Question.id}
//           className="w-full flex flex-col items-start rounded-sm m-2 p-2  lg:m-8 lg:p-4"
//         >
//           <div className="text-base font-normal text-[#2d2d2d]">
//             <BlockContent blocks={Question.question} />
//           </div>
//           <div className="lg:my-2">
//             {Question.options.map((z) => {
//               return <BlockContent key={z.id} blocks={z.optionValue} />;
//             })}
//           </div>
//           <div className="my-2 flex flex-col items-start space-y-3">
//             <strong>Correct Answer: </strong>
//             {Question.options[Question.solution.correctAns - 1] && (
//               <BlockContent
//                 blocks={
//                   Question.options[Question.solution.correctAns - 1].optionValue
//                 }
//               />
//             )}
//           </div>
//           <div className="">
//             <h1 className="py-2">
//               <strong>Exaplanation: </strong>
//             </h1>
//             <BlockContent blocks={Question.solution.explanation} />
//           </div>
//         </div>
//       )}

//       {Question._type == "genericQuestion" && (
//         <div className=" border border-[#030260] my-8 m-2 p-2 lg:m-8 rounded-lg lg:p-[2vw] flex flex-col items-start space-y-4 ">
//           <div className="flex flex-col items-start space-y-2 text-lg w-full  border-b border-gray-950 pb-4">
//             <strong className="">Question:</strong>
//             <BlockContent blocks={Question.questionValue} />
//           </div>
//           <div className="flex flex-col items-start space-y-2 text-lg w-full  border-b border-gray-950 pb-4">
//             <strong className="">Ans:</strong>
//             <BlockContent blocks={Question.Answer} />
//           </div>
//         </div>
//       )}
//       <Footer />
//     </div>
//   );
// };
