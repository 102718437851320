import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { urlFor } from "../../../../sanityCli";
// import BlockContent from "@sanity/block-content-to-react";
import Header from "../../../header/Header";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import { ListContent } from "../../../reusableComponent/ListContent";
import { PrelimsContentBox } from "../../../reusableComponent/PrelimsContentBox";
import Table2 from "../../../reusableComponent/Table2";
import { DataContext } from "../../../../context/DataContext";
import {
  HeroHeaderSyllabus,
  HeaderButton,
  HeroHeader,
} from "../../HeroHeaderSyllabus";
import { RightSideBar } from "../../../rightSideBar/RightSideBar";

export const NotesDetailPage = () => {
  const { exams,loading } = useContext(DataContext);
  const location = useLocation();
  const { subjectName, topic } = useParams();

  if (loading) {
    return <></>;
  }

  const syllabus = exams[0].subExams[0].syllabus;
  const currentTopic = location.state.subjectNotes.topics.find(
    (x) => x.header === topic
  );
  const topicContents = currentTopic ? currentTopic.topicContent : [];

  const headerButtons = [
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} Questions`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/questions`
    ),
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} PYQs`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/pyqs`
    ),
  ];

  const header = new HeroHeader(
    headerButtons,
    `${subjectName} - ${topic}`,
    syllabus.author,
    syllabus.date
  );

  return (
    <>
      <Header />
      <div>{header && <HeroHeaderSyllabus props={header} />}</div>
      <main className="flex p-2 lg:p-[4vw] items-start gap-8 mb-10">
        {/* Left side component: scrollable */}
        <div className="w-full lg:w-[75%] bg-[#FFF] flex flex-col items-start rounded-sm lg:p-4 lg:overflow-y-auto">
          {topicContents.map((x) =>
            x.items.map((y, index) =>
              y._type === "listContent" ? (
                <ListContent key={index} listContents={[y]} />
              ) : y._type === "ContentBoxWithImageCheckbox" ? (
                <PrelimsContentBox key={index} contentData={[y]} />
              ) : y._type === "tableData" ? (
                <Table2 key={index} title={y.title} data={y} />
              ) : null
            )
          )}

          <div className="w-full flex items-center justify-center">
            {topicContents.map((x) =>
              x.images.map((image, index) => (
                <img key={index} src={urlFor(image)} className="w-auto my-4" alt="Topic Content" />
              ))
            )}
          </div>
        </div>

        {/* Right side component: sticky */}
        <div className="hidden lg:block lg:w-[25%] sticky top-28 h-fit">
          <RightSideBar
            examName="Upsc"
            subExamName="Mains"
            title={subjectName}
          />
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
