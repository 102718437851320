import React, {useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import Header from "../header/Header";
import { Footer } from "../footer/Footer";
import ScrollToTopButton from "../../unusableComponent/utils/scrollToTopButton";
import {
  HeroHeaderSyllabus,
  HeroHeader,
  HeaderButton,
} from "../reusablepages/HeroHeaderSyllabus";

export const Blog = () => {
  const navigate = useNavigate();
  const { path } = useParams();
  const [blogsData, setBlogsData] = useState(null);
  const { exams, blogs, loading } = useContext(DataContext);

  if (loading) return <></>;

  if (!exams || !exams.length || !blogs || !blogs.length) {
    return <div>Data not available</div>;
  }

  // const syllabus = exams[0]?.subExams[0]?.syllabus;

  var headerButtons = [
    new HeaderButton(
      `${exams[0]?.examName} ${exams[0]?.subExams[0]?.title} Questions`,
      `/${exams[0]?.examName}/${exams[0]?.subExams[0]?.title}/questions`
    ),
    new HeaderButton(
      `${exams[0]?.examName} ${exams[0]?.subExams[0]?.title} PYQs`,
      `/${exams[0]?.examName}/${exams[0]?.subExams[0]?.title}/pyqs`
    ),
  ];

  // const today = new Date();
  // const year = today.getFullYear();
  // const month = String(today.getMonth() + 1).padStart(2, "0");
  // const day = String(today.getDate()).padStart(2, "0");
  // const formattedDate1 = `${year}-${month}-${day}`;

  const header = new HeroHeader(
    headerButtons,
    `${blogs[0]?.blogPage}`,
  );

  if (!blogsData && blogs) {
    setBlogsData(blogs[0].blogsData.filter((x) => x.tags.includes(path)));
  }

  const handleBoxClick = (blog) => {
    navigate(`/blog/${blog.path}`, { state: { blog } });
  };

  return (
    <>
      <Header />
      <div className="lg:mt-[12vw]">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <div className="mb-24">
        <div className="w-full flex items-center justify-center lg:pt-10">
          {blogs && (
            <h1 className="text-[#007fff] capitalize text-xl lg:text-[2vw] lg:leading-[3vw] font-bold">
              {blogs[0]?.blogPage}
            </h1>
          )}
        </div>
        <div className="w-full flex flex-wrap items-center justify-between px-[4vw] pb-[3vw]">
          {blogs &&
            (!blogsData || blogsData.length === 0) &&
            blogs[0].blogsData.map((blog, blogIndex) => (
              <div
                key={`${blogIndex}`}
                className="blog-box w-full lg:w-[24%] bg-[#fff] shadow-lg rounded-lg  p-4 lg:mt-8 mt-4 cursor-pointer transition-all duration-300 ease-in-out hover:shadow-xl hover:transform hover:-translate-x-1"
                onClick={() => handleBoxClick(blog)}
              >
                <span className="flex items-center w-full gap-4 mb-6">
                  {blog.tags &&
                    blog.tags.map((text, i) => (
                      <Link
                        to={`/blogs/tag/${i}`}
                        key={i}
                        className="w-fit bg-[#007fff] px-2 py-1 text-white text-xs font-semibold rounded-md transition-all duration-300 ease-in-out group-hover:bg-white group-hover:text-[#030260]"
                      >
                        {text}
                      </Link>
                    ))}
                </span>
                <p className="text-lg font-bold mb-2 text-gray-700 transition-all duration-300 ease-in-out hover:text-[#030260]">
                  {blog.header}
                </p>
                <p className="text-sm font-semibold mb-2 text-gray-700 transition-all duration-300 ease-in-out hover:text-[#030260]">
                  {blog.intro}
                </p>
                <span className="text-[#030260] text-xs font-semibold flex items-center gap-2 transition-all duration-300 ease-in-out hover:text-[#030260]">
                  <span>{blog.author}</span>
                  <span className="w-2 h-2 bg-[#030260] rounded-full"></span>
                  <span>{new Date(blog.date).toLocaleDateString()}</span>
                </span>
              </div>
            ))}

          {blogs &&
            blogsData &&
            blogsData.map((blog, blogIndex) => (
              <div
                key={`${blogIndex}`}
                className="blog-box lg:w-[30%] w-full bg-slate-50 shadow-lg rounded-lg p-4 py-8 mt-8 cursor-pointer transition-all duration-500 ease-in-out hover:shadow-xl hover:transform hover:-translate-y-2"
                onClick={() => handleBoxClick(blog)}
              >
                <span className="flex items-center w-full gap-4 mb-6">
                  {blog.tags &&
                    blog.tags.map((text, i) => (
                      <span
                        key={i}
                        className="w-fit bg-[#007fff] px-2 py-1 text-white text-xs font-semibold rounded-md transition-all duration-300 ease-in-out  group-hover:text-[#030260]"
                      >
                        {text}
                      </span>
                    ))}
                </span>
                <p className="text-lg font-bold mb-2 text-gray-700 ">
                  {blog.header}
                </p>
                <p className="text-sm font-semibold mb-2 text-gray-700 ">
                  {blog.intro}
                </p>
                <span className="text-[#030260] text-xs font-semibold flex items-center gap-2">
                  <span>{blog.author}</span>
                  <span className="w-2 h-2 bg-[#030260] rounded-full"></span>
                  <span>{new Date(blog.date).toLocaleDateString()}</span>
                </span>
              </div>
            ))}
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
