import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import BlockContent from "@sanity/block-content-to-react";

export const HomePracticeSec = () => {
  const { practiceQuestion, loading } = useContext(DataContext);
  // console.log("practiceQuestion on homepage", practiceQuestion[0].questionAns);
  // console.log("practiceQuestion on homepage", practiceQuestion[0].questionAns);
  const items = practiceQuestion[0].questionAns.map((item) => {
    return item;
  });
  console.log("items", items);
  const navigate = useNavigate();

  if (loading) return <div></div>;

  return (
    <div className="practice-question-section flex flex-col items-center justify-center lg:mb-4 px-[2vw]">
      {practiceQuestion && (
        <h1 className="text-2xl lg:text-[3vw] font-semibold text-[#007fff]">
          {practiceQuestion[0].title.split(" ").slice(0, 2).join(" ")}{" "}
          <span className="text-[#FF2C45]">
            {practiceQuestion[0].title.split(" ").slice(2, 5).join(" ")}
          </span>
        </h1>
      )}
      <div className="bg-white relative my-4 lg:my-8 p-4 lg:pt-[4vw] lg:px-[4vw]  flex flex-wrap items-start justify-between shadow-xl border-y-2 border-[#030260] rounded-lg">
        {items &&
          items[0] &&
          items[0].items.map((item, index) => (
            <div key={index} className={`w-full lg:w-[48%] mb-6 flex flex-col `}>
              <div className="flex flex-col items-start justify-between">
                {item && (
                  <div className="w-full text-gray-700 font-medium border-b border-[#030260] pb-4 lg:pb-4">
                    <div className="text-gray-800">
                      <BlockContent blocks={item.questionValue} />
                    </div>
                    <button
                      className="bg-[#007fff] px-2 py-1 hover:translate-y-[2px] transition duration-300 rounded-md text-sm font-medium text-white mt-2"
                      onClick={() =>
                        navigate("/practiceDetail", { state: item })
                      }
                    >
                      See Answer
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="devider_line hidden lg:block w-[2px] bg-[#030260] min-h-full absolute top-0 bottom-0 left-1/2"></div>
      </div>
    </div>
  );
};

// import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { DataContext } from "../../context/DataContext";
// import BlockContent from "@sanity/block-content-to-react";

// export const HomePracticeSec = () => {
//   const { practiceQuestion, loading } = useContext(DataContext);
//   console.log("practiceQuestion on homepage", practiceQuestion[0].questionAns);
//   console.log("practiceQuestion on homepage", practiceQuestion[0].questionAns);
//   const items = practiceQuestion[0].questionAns.map((item) => {
//     return item;
//   });
//   console.log("items", items);
//   const navigate = useNavigate();

//   if (loading) return <div>Loading...</div>;

//   return (
//     <div className="practice-question-section flex flex-col items-center justify-center lg:mb-4 px-[4vw]">
//       {practiceQuestion && (
//         <h1 className="text-2xl lg:text-[3vw] font-semibold text-[#007fff]">
//           {practiceQuestion[0].title.split(" ").slice(0, 2).join(" ")}
//           <span className="text-[#FF2C45]">
//             {practiceQuestion[0].title.split(" ").slice(2, 5).join(" ")}
//           </span>
//         </h1>
//       )}
//       <div className=" bg-white relative h-[660px] lg:h-[660px] md:h-[400px] overflow-y-auto my-4 lg:my-8 p-4 lg:py-[5vw] lg:px-[6vw] flex flex-wrap items-start justify-between shadow-xl border-y-2 border-[#030260] rounded-lg">
//         {items &&
//           items[0] &&
//           items[0].items.map((item, index) => (
//             <>
//               {/* below design is for mobile and tab  */}
//               <div key={index} className={`lg:hidden w-full flex flex-col`}>
//                 <div className="flex flex-col items-start justify-between">
//                   {item && (
//                     <div className="w-full text-gray-700 font-medium border-b border-[#030260] mt-4 pb-6 lg:pb-4">
//                       <div className="text-gray-800">
//                         <BlockContent blocks={item.questionValue} />
//                       </div>
//                       <button
//                         className="bg-[#007fff] px-2 py-1 hover:translate-y-[2px] transition duration-300 rounded-md text-sm font-medium text-white mt-2"
//                         onClick={() =>
//                           navigate("/practiceDetail", { state: item })
//                         }
//                       >
//                         See Answer
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </div>
//               {/* below design is for Desktop devices  */}
//               <div
//                 key={index}
//                 className={`relative lg:flex flex-wrap items-start justify-between main-container`}
//               >
//                 {index % 2 === 0 ? (
//                   <div className="w-[90%]">
//                     <BlockContent blocks={item.questionValue} />
//                     <button
//                       className="bg-[#007fff] px-2 py-1 hover:translate-y-[2px] transition duration-300 rounded-md text-sm font-medium text-white mt-2"
//                       onClick={() =>
//                         navigate("/practiceDetail", { state: item })
//                       }
//                     >
//                       See Answer
//                     </button>
//                   </div>
//                 ) : (
//                   <div className="w-[90%]">
//                     <BlockContent blocks={item.questionValue} />
//                     <button
//                       className="bg-[#007fff] px-2 py-1 hover:translate-y-[2px] transition duration-300 rounded-md text-sm font-medium text-white mt-2"
//                       onClick={() =>
//                         navigate("/practiceDetail", { state: item })
//                       }
//                     >
//                       See Answer
//                     </button>
//                   </div>
//                 )}

//               </div>
//               <div className="absolute top-0 bottom-0 left-1/2 bg-black w-1 h-full">

// </div>
//             </>
//           ))}
//       </div>
//     </div>
//   );
// };

export default HomePracticeSec;
